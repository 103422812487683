<template lang="">
  <div>
    <!-- {{ orderEntries }} -->
    <div>
      <b-row class="match-height">
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            color="warning"
            icon="UsersIcon"
            :statistic="orderEntries.length"
            statistic-title="Bestellungen"
          />
        </b-col>
        <!-- <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            color="primary"
            icon="UserPlusIcon"
            :statistic="
              newestCustomer.firstName + ' ' + newestCustomer.lastName
            "
            statistic-title="Neuster Kunde"
          />
        </b-col> -->
        <!-- <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          icon="ShoppingCartIcon"
          :statistic="mostOrder"
          statistic-title="Meiste Bestellungen"
          color="info"
        />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="DollarSignIcon"
          statistic="Elias Koutsonas (278,00€)"
          statistic-title="Meister Umsatz"
        />
      </b-col> -->
      </b-row>
    </div>

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <b-table
            ref="refListTable"
            striped
            hover
            responsive
            :items="orderEntries"
            :fields="fields"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
          >
            <template #cell(orderDate)="data">
              {{ getFormattedDate(data.item.orderDate) }}
            </template>

            <template #cell(customer)="data">
              {{ data.value.firstName }} {{ data.value.lastName }}
            </template>

            <template #cell(order_items)="data">
              <b-badge variant="warning">
                <!-- {{data.value[data.index].storage}} -->
                {{ data.value.length }}
              </b-badge>
            </template>
            <template #cell(price)="data">
              <b-badge variant="success">
                {{
                  data.item.order_items && data.item.order_items.length > 0
                    ? data.item.order_items
                        .reduce((total, item) => total + item.price, 0)
                        .toFixed(2)
                    : 'N/A'
                }}
                €
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{ name: 'orders-view', params: { id: data.item.id } }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Öffnen</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="editObject(data)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Bearbeiten</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteWarning(data)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Löschen</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import helper from '@/helper/helper'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      getFormattedDate,
      refetchData,
    }
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    vSelect,
    StatisticCardVertical,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'orderDate',
          label: 'Buchungsdatum',
          sortable: true,
        },
        {
          key: 'orderDetails',
          label: 'Buchungsdetails',
        },
        {
          key: 'customer',
          label: 'Kunde',
        },
        {
          key: 'order_items',
          label: 'Positionen',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Summe',
          sortable: true,
        },
        { key: 'actions', label: 'Aktionen' },
      ],
      orders: [],
    }
  },
  computed: {
    orderEntries() {
      return this.$store.getters.orderEntries
    },
    getTotalRows() {
      return this.orderEntries.length
    },
  },
  updated() {
    this.totalRows = this.getTotalRows
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/orders-view/${item.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
